import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-144 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

export default ({
  subheading = "Marketing Strategy",
  subheading2 = "Objectives and Key Results",
  subheading3 = "Marketing Methods",
  headingHtmlComponent = (
    <>
      Marketing <span tw="text-primary-500">Services.</span>
    </>
  ),
  description2="Once your marketing strategy is established, employ Objectives and Key Results (OKRs) to achieve company goals. Hailing from Silicon Valley, OKRs are widely employed to convey priorities. Each OKR comprises an Objective and Key Result: Objectives are concise, motivating challenges, while Key Results measure progress towards them.",
  description3="Social media and the rapid flow of information have revolutionized how businesses advertise. Byonity prioritizes Inbound Marketing, Social Media Presence, and Earned Media to achieve Key Results outlined by OKRs.",
  description = "A marketing strategy addresses: What problems does your business solve, and why is your solution better than competitors'? It guides actions to achieve business goals. Without one, revenue efforts are disjointed, difficult to measure, and wasteful. Yet, many small businesses lack this crucial strategy, despite its proven success.",
  // linkText = "View all Projects",
  // cardLinkText = "Read Case Study",
  textOnLeft = false
}) => {
  const cards = [
    {
      imageSrc:
        "../Marketing.svg",
      company: "",
      type: "",
      title: "",
      durationText: "",
      locationText: ""
    },
    {
      imageSrc:
        "../Marketing1.svg",
      company: "",
      type: "",
      title: "",
      durationText: "",
      locationText: ""
    }
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <Subheading>{subheading}</Subheading>
              <HeadingDescription>{description}</HeadingDescription>
              <Subheading>{subheading2}</Subheading>
              <HeadingDescription>{description2}</HeadingDescription>
              <Subheading>{subheading3}</Subheading>
              <HeadingDescription>{description3}</HeadingDescription>
              {/* <PrimaryLink>
                {linkText} <ArrowRightIcon />
              </PrimaryLink> */}
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    {/* <CardMetaFeature>
                      <TimeIcon /> {card.durationText}
                    </CardMetaFeature> */}
                    {/* <CardMetaFeature>
                      <LocationIcon /> {card.locationText}
                    </CardMetaFeature> */}
                  </CardMeta>
                  {/* <CardAction>{cardLinkText}</CardAction> */}
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
