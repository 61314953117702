import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import TeamIllustrationSrc from "images/team.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

// const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  heading = (
    <>
      Business Planning and <span tw="text-primary-500">Execution</span>.
    </>
  ),
  subheading = "Business Planning",
  description = "The internet is full of reasons why having a business plan is important for all businesses from local mom and pop shops to large organizations. Yes, it’s used to understand business opportunities and bring focus to ideas, solutions and strategies. Yes, it’s required by investors to secure capital, credit or attract customers. What’s most important to every entrepreneur is that having a business plan improves chances of success by reducing the probability of failure. There are no guarantees that having a business plan will result in a profitable enterprise, that will be decided by market forces. It’s hard enough to launch or grow a business. Moving forward without a plan is an unnecessary risk.",
  subheading2 = "Project Execution",
  description2 = "Starting or expanding a business is good, tough work that demands long hours and lots of attention the smallest details. Our business development team has helped many entrepreneurs by delivering projects to propel their business forward so they can focus on operations. While each project is unique, our methods were developed from hundreds of successful executions and a few hard lessons learned. We relentlessly focus on results and are transparent to ensure delivery consistency with high quality outcomes our clients have come to expect.",
  // primaryButtonText = "See Our Portfolio",
  // primaryButtonUrl = "https://timerse.com",
  features = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    // {
    //   Icon: BriefcaseIcon,
    //   title: "Professionalism",
    //   description: "We have the best professional marketing people across the globe just to work with you."
    // },
    // {
    //   Icon: MoneyIcon,
    //   title: "Affordable",
    //   description: "We promise to offer you the best rate we can - at par with the industry standard."
    // }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container style={{backgroundColor:'#edf7fc'}}>
      <TwoColumn style={{paddingTop:'0rem'}}>
        <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Subheading>{subheading}</Subheading>
            <Description>{description}</Description>
            <Subheading>{subheading2}</Subheading>
            <Description>{description2}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
