import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { toast } from 'react-toastify';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input, textarea {
    ${tw`w-full text-gray-1000 text-base font-medium tracking-wide border-b-2 py-2 text-gray-1000 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw.svg`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default ({ closeModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    interestedIn: "" ,
    message: ""
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    interestedIn: false,
    message: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Reset the error state when the user starts typing again
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    const errors = {};
    if (formData.name.trim() === "") {
      errors.name = true;
    }
    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      errors.email = true;
    }
    if (formData.interestedIn.trim() === "") { 
      errors.interestedIn = true;
    }
    if (formData.message.trim() === "") {
      errors.message = true;
    }

    // If there are errors, set the error state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      // Call the API with form data if validation passes
      fetch("https://byonitywebsite-emailsender.azurewebsites.net/api/Function1", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: formData.name,
          toEmail: formData.email,
          subject: "Inquiry",
          interestedIn: formData.interestedIn,
          body: formData.message
        })
      })
      .then(res => {
        alert("Submitted successfully!");
        closeModal();
        console.log(res);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
    }
  };

  // Function to validate email format
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Container>
      <Content>
        <FormContainer>
          <br></br>
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <Label htmlFor="name-input">Name</Label>
              <Input id="name-input" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} style={{color:'black !important', paddingLeft:'0.5rem'}} />
              {formErrors.name && <span tw="text-red-500">Please enter your name</span>}
            </InputContainer>
            <InputContainer>
              <Label htmlFor="email-input">Email</Label>
              <Input id="email-input" type="email" name="email" placeholder="Email address" value={formData.email} onChange={handleChange} style={{color:'black !important', paddingLeft:'0.5rem'}}/>
              {formErrors.email && <span tw="text-red-500">Please enter a valid email address</span>}
            </InputContainer>
            <InputContainer>
            <Label>Interested in</Label>
            <div style={{ display: 'flex' }}>
            <input type="radio" id="business-planning" style={{ width: 'auto', marginRight: '5px' }} name="interestedIn" value="Business Planning & Execution" onChange={handleChange} checked={formData.interestedIn === "Business Planning & Execution"} />
            <label htmlFor="business-planning">Business Planning & Execution</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" id="marketing" style={{ width: 'auto', marginRight: '5px' }} name="interestedIn" value="Marketing" onChange={handleChange} checked={formData.interestedIn === "Marketing"} />
              <label htmlFor="marketing">Marketing</label>
            </div>
            <div style={{ display: 'flex' }}>
            <input type="radio" id="web-development" style={{ width: 'auto', marginRight: '5px' }} name="interestedIn" value="Web Development" onChange={handleChange} checked={formData.interestedIn === "Web Development"} />
            <label htmlFor="web-development">Web Development</label>
            </div>
            {formErrors.interestedIn && <span tw="text-red-500">Please select an option</span>}
            </InputContainer>
            <InputContainer>
              <Label htmlFor="message-input">Message</Label>
              <TextArea id="message-input" name="message" placeholder="How can we help?" value={formData.message} onChange={handleChange} style={{color:'black !important', paddingLeft:'0.5rem'}}/>
              {formErrors.message && <span tw="text-red-500">Please enter a message</span>}
            </InputContainer>
            <SubmitButton type="submit">Send</SubmitButton>
            &nbsp;
            <SubmitButton onClick={closeModal}>Close</SubmitButton>
          </form>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
