import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js"
import SimpleContactUs from "components/forms/SimpleContactUs.js";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-1 lg:py-1`;

const Row = tw.div`flex items-center justify-between px-8`;

const CopyrightText = tw.p`text-center mt-2 mb-2 font-medium tracking-wide text-sm text-gray-100`;
const ContactLink = tw.a`text-center mt-2 mb-2 font-medium tracking-wide text-sm text-gray-100`;
export default () => {
  
  const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50 bg-black`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Container>
      <Content>
        <Row>
          <div>
            <CopyrightText>
              &copy; Copyright 2024, Byonity Corp.
            </CopyrightText>
          </div>
          <ContactLink href="/#" onClick={toggleModal}>Contact Us</ContactLink>
        </Row>
      </Content>
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          Close
        </CloseModalButton>
        <div className="content">
          <SimpleContactUs closeModal={closeModal} />
        </div>
      </StyledModal>
    </Container>
  );
};
