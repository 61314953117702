import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import Footer from "components/footers/MiniCenteredFooter.js";

export default () => (
  
  <AnimationRevealPage>
    <Hero />
    <MainFeature />
    <Features />
    <MainFeature2 />
    <Portfolio />
    <Testimonial
      heading={
        <>
          Web<span tw="text-primary-500"> Development.</span>
        </>
      } 
      subheading={
        <h2>Custom Development</h2>
      }
      subheading2={
        <h2>Delivery Process</h2>
      }
      description2 = "Creating a website involves our Agile development method. We aim to deliver fully functional features every one or two weeks. We start with a conversation to understand your needs and create a backlog. We prioritize tasks based on your business priorities. Then, we build your site in one or two-week increments until it's ready for deployment. After deployment, we can continue updating per the backlog or provide ongoing support. Our approach is flexible, transparent, and consistent, ensuring you're informed and satisfied throughout the process."
      description = "Choosing a website platform can be time-consuming and costly if you make the wrong choice. With our team, you can avoid the complexities of development and focus on messaging to your customers. We handle design, Search Engine Optimzations, and navigation, ensuring your content resonates with your audience."
      testimonials={[
        {
          imageSrc:
            "../Webdevelopment.svg",
          profileImageSrc:
            "",
        
          // customerName: "Charlotte Hale",
          // customerTitle: "CEO, Tesla Inc."
        },
        {
          imageSrc:
            "",

       
          // customerName: "Adam Cuppy",
          // customerTitle: "Founder, Nestle"
        }
      ]}
      textOnLeft={true}
    />
    <Footer />
  </AnimationRevealPage>
);
